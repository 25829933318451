// home page
.hero_component {
    padding-top: 100px;
    width: 100%;
    height: 800px;
    overflow-x: hidden;
    padding-bottom: 60px;    
    padding-bottom: 150px;

    .wrapper{
        height: 100%;
        .left{
            padding-left: 8.6%;
            width: 67%;
            position: relative;
            .title{
                font-size: 4.2rem;
                font-weight: 700;
            }
            .text{
                color: $gray_text;
                font-size: 18px;
                max-width: 75%;
                margin: 35px 0px;
            }
            button{
                background: #287BDD;
                color: #fff;
                border-radius: 6px;
                padding: 7px 6%;
            }
        }
        .right{
            position: relative;
            width: 40%;
            height: 100%;
            overflow: initial;
            .image{
                width: 120%;
                right: 0;
                bottom: 10%;
                z-index: -1;
                position: absolute;
                img{
                    height: 100%;
                    width: 100%;
                }
            }
        }
        .drop_shadow{
            background: #1BAAFA;
            filter: blur(160px);
            position: absolute;
            width: 200px;
            height: 100px;
        }
        .drop_shadow_1{
            transform: matrix(1, 0.27, -0.04, 0.97, 0, 0);
            right: 29%;
            bottom: 0;
        }
        .drop_shadow_2{
            transform: matrix(0.99, -0.16, 0.12, 0.99, 0, 0);
            left: 25%;
            bottom: 25%;
        }
        .drop_shadow_3{
            transform: matrix(1, -0.01, 0.04, 1, 0, 0);
            right: 0%;
            top: 0;
        }
        .drop_shadow_4{
            transform: matrix(0.99, -0.16, 0.12, 0.99, 0, 0);
            left: 0%;
            top: 0;
        }
        .drop_shadow_5{
            transform: matrix(0.97, 0.46, -0.21, 0.92, 0, 0);
            right: 0%;
            bottom: 10%;
        }
    }


    @media (max-width: 991px) {
        height: auto;
        .wrapper{
            flex-direction: column-reverse;
            .left{
                padding-left: 8.6%;
                width: 100%;
                position: relative;
                margin-top: -40%;

                .title{
                    max-width: 500px;
                    font-size: 3rem;
                }
            }
            .right{
                width: 100%;
                z-index: -1;
                .image{
                    width: 100%;
                    right: 0;
                    bottom: inherit;
                    z-index: 1;
                    position: relative;
                }
            }
        }
    }
}





// about page
.about_page_about_tab{

    .slick-prev, .slick-next{
        display: none!important;
    }

    .title{
        font-size: 42px;
        font-weight: 700;
        margin-bottom: 15px;
    }

    .hero_box{
        width: 83%;
        margin: auto;
        margin-top: 50px;

        .wrapper{
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: rgba(255, 255, 255, 0.04);
            border: 1px solid rgba(255, 255, 255, 0.08);
            border-radius: 16px;

            .image {
                width: 48.4125%;
                padding: 30px;
            }
            .content{
                width: 48.4125%;
                .title{
                    font-size: 42px;
                    font-family: 'lato';
                    margin-bottom: 15px;
                }
                .texts{
                    max-width: 80%;
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 22px;
                }
            }
        }
    }


    .box{
        .wrapper{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 8.47457627119%;

            .item{
                padding: 0 20px;
                text-align: center;
                .image{
                    width: 112px;
                    height: 112px;
                    margin: auto;
                    margin-bottom: 20px;
                }
                .texts{
                    font-weight: 400;
                    line-height: 22px;
                }
            }
        }
    }

    .partners{
        margin: 60px 0;
        margin-bottom: 80px;
        .title{
            margin-bottom: 50px;
        }
        .wrapper{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 20px;
        }
    }

    .featured_in{
        margin: 100px auto;
        .title{
            margin-bottom: 50px;
        }
        .slick-track{
            display: flex;
            align-items: center;
        }
        .slick-center.slick-current {
            .item{
                background-color: #29292dc7;
            }
        }
        .item{
            padding: 20px;
        }
    }
}


.about_page_team {
    .title {
        text-align: center;
        margin-top: 40px;
        margin-bottom: 40px;
    }

    .filter_buttons{
        
        .wrapper{
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            .item{
                margin: 0 10px;
                margin-bottom: 15px;
                &:first-child{
                    margin-left: 0;
                }
                &:last-child{
                    margin-right: 0;
                }
                button{
                    border: 1px solid rgba(255, 255, 255, 0.24);
                    border-radius: 16px;
                    background: #020305;
                    color: #fff;
                    padding: 5px 25px;
                    line-height: 25px;
                }
                &.active {
                    button{
                        background: #fff;
                        color: #434447;
                    }
                }
            }
        }
    }

    .filters {
        margin-top: 20px;
        .total_member_in_filter{
            font-weight: 700;
            font-size: 16px;
            text-align: center;
            margin-bottom: 15px;
        }
        .fliter_wrapper{
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            justify-content: space-between;

            .card_item{
                border: 1px solid rgba(255, 255, 255, 0.089);
                border-radius: 16px;
                background: rgba(29, 56, 80, 0.38);
                margin-bottom: 30px!important;

                img{
                    object-fit: cover!important;
                }

                .details{
                    padding: 0 15px;
                    margin-top: 15px;
                    margin-bottom: 15px;
                    .member_title{
                        font-size: 13px;
                        color: #86888C;
                        margin-bottom: 15px;
                    }
                    .name{
                        font-size: 18px;
                        color: #fff;
                        padding-bottom: 15px;
                        border-bottom: 1px solid #86888c8f;
                    }
                }

                .social_icons {
                    width: 100%;
                    .wrapp {     
                        display: flex;
                        align-items: center;
                        padding: 0 15px;
                        margin-bottom: 20px;
                        width: 110px;
                        max-width: 100%;
                        justify-content: space-between;

                        .item{
                            width: 15px;
                            svg{
                                width: 15px;
                                height: 15px;
                            }
                        }
                    }
                }
            }
        }
    }
}
// team page member overlay 
.team_member_overlay{
    position: fixed;
    background: #bdbdbd00;
    z-index: 999999999;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    .section{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #ffffff3b;

        .wrapper{
            width: 100%;
            display: flex;
            justify-content: space-between;
            background: #020305e0;
            border: 1px solid rgba(255, 255, 255, 0.24);
            border-radius: 16px;
            position: relative;

            .title{
                text-align: left;
                margin-bottom: 10px;
            }
            .subtitle{
                font-size: 32px;
                color: #86888C;
                font-weight: 600;
                span{
                    border-bottom: 1px solid #287BDD;
                    padding-bottom: 8px;
                }
            }

            .social_icons {
                width: 100%;
                margin-top: 30px;
                .wrapp {     
                    display: flex;
                    align-items: center;
                    margin-bottom: 20px;
                    width: 110px;
                    max-width: 100%;
                    justify-content: space-between;

                    .item{
                        width: 20px;
                        svg{
                            width: 20px;
                            height: 20px;
                        }
                    }
                }
            }
        }
        .contents{
            font-size: 14px;
            line-height: 1.3;
            word-spacing: 1px;
            font-weight: 400;
        }
        .image_part{
            border-radius: 8px 8px 0px 0px;
            overflow: hidden;
            position: relative;
            top: 35px;
        }
        .close_btn{
            border-radius: 100%;
            width: 40px;
            height: 40px;
            padding: 0;
            svg{
                width: 24px;
                height: 24px;
                color: #fff;
            }
        }
    }
}






// Technology page
.content_wrapper{
    display: flex;
    flex-direction: column;
    width: 75%;
    // height: 1200px;
    // border: white 1px solid;
    position: relative;
    margin: 50px auto;
    justify-content: center;
    gap: 30px;   
}

.technology_card{
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 280px;
    width: 90%;
    max-width: 920px;
    margin-left: auto;
    margin-right: auto;
    background: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(255, 255, 255, 0.08);
    box-sizing: border-box;
    border-radius: 16px;
    .left_content{
        width: 25%;
        height: 100%;
        padding-top: 35px;
        padding-left:100px;
        // border: red 1px solid;
        img{
            height: 110px;
            width: 100px;
            // border: yellow 1px solid;
        }
    }
    
    .right_content{
        width: 75%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: left;
        align-items: center;
        // border: red 1px solid;
        .right_item1{
            // border: #1BAAFA 1px solid;
            flex-grow: 1;
            width: 100%;
            display:flex;
            align-items:flex-end;
        }
    
        .right_item2{   
            // border: #1BAAFA 1px solid;
            flex-grow: 2;
            width: 100%;
            display:flex;
            align-items:center;
            padding-right: 15%;
            p{
                font-family: 'Lato';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 25px;
                color: #86888C;
            }
        }
    
    
        .right_item3{
            // border: #1BAAFA 1px solid;
            flex-grow: 1;
            width: 100%;
            display: flex;
            align-items: flex-start;
        }
    }
    
}





/// research page 
.research_page_sections{
    margin-top: 50px;

    .item {
        margin-bottom: 50px;
        .wrapper{
            display: flex;
            ul{
                padding-left: 2%;
            }
            .title{
                text-align: right;
            }
        }
    }

    .research_partners{
        margin: 80px 0;
        .title{
            margin-top: 50px;
        }
        .wrapper{
            width: 100%;
            max-width: 1000px;
            display: flex;
            margin: auto;
            align-items: center;
            justify-content: space-evenly;
            margin-top: 50px;

            .left, .right{
                width: 40%;
            }
        }
    }


    .library_wrapper{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;

        .card_item{
            background: #11151B;
            border-radius: 8px 8px 16px 16px;
            overflow: hidden;
            transition: all .3s;
            margin-bottom: 60px;

            &:hover{
                margin-top: -30px;
                // .title{
                //     color: #287BDD;
                // }
            }
            
            .content{
                padding: 20px;
            }
            .image{
                width: 100%;
                background: #287BDD;    
                display: flex;
                align-items: flex-end;
                padding: 10px 20px;
            }
            .title{
                font-size: 18px;
                margin-bottom: 15px;
                font-weight: 500;
            }
            .text{
                font-size: 13px;
                color: #86888C;
                margin-bottom: 15px;
            }
            .date{
                font-size: 13px;
            }
        }
    }


    .library_item_wrapper{

        .back_btn{
            button{
                font-size: 13px;
                color: #fff;
                margin-bottom: 15px;
                &:hover{
                    color: #1976d2;
                }
            }
        }

        .view_paper_button{
            margin-top: 50px;
            .icon{
                font-size: 25px;
            }
            button{
                display: flex;
                align-items: center;
                padding: 5px 35px;
                border: 1px solid;
                border-radius: 8px;
                color: #fff;
                &:hover{
                    color: #1976d2;
                }
            }
        }

        .head_part {
            margin-bottom: 25px;
            .sub_title{
                font-size: 13px;
                color: #86888C;
                margin: 10px 0;
            }
            .date{
                font-size: 13px;
            }
        }
        .text_section {
            .section{
                font-size: 14px;
                margin-bottom: 20px;
                &.title{
                    font-size: 24px;
                }
            }
        }
    }


    .research_page_video_tab {

        .video_items {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            justify-content: space-between;
            .item{
                border-radius: 8px 8px 16px 16px;
                overflow: hidden;
                background: #11151B;
                border: 1px solid rgba(255, 255, 255, 0.24);
                transition: all .5s;

                .image {    
                    position: relative;
                    .video_icon {
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        .icon{
                            width: 42px;
                            height: 42px;
                            border-radius: 50%;
                            border: 1px solid #fff;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            svg{
                                transform: rotate(327deg);
                                position: relative;
                                top: -2px;
                                left: -2px;
                                font-size: 20px;
                            }
                        }
                    }
                }
                img{
                    object-fit: cover;
                }
                &:hover{
                    margin-top: -30px;
                    .content{
                        color: #287BDD;
                    }
                    .video_icon {
                        background: #80808045;
                    }
                }
                .content{
                    padding: 20px 20px;
                    font-size: 18px;
                    text-align: center;
                }
            }
        }

    }
}




/// blog page
.blog_page {

    .blog_page_head {
        width: 100%;
        height: 400px;
        .bg_image {
            width: 100%;
            height: 100%;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;

            .full_wrapper{
                width: 100%;
                height: 100%;
                background: #000000b0;
                padding-top: 100px;

                .page_content {
                    width: 100%;
                    height: 100%;
                    .wrapper{
                        width: 100%;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        .subtitle{
                            font-size: 13px;
                            color: #fff;
                        }
                        .title{
                            color: #fff;
                            margin: 25px 0;
                        }
                        button{
                            color: #fff;
                            background: transparent;
                            border-radius: 32px;
                            border: 2px solid #287BDD;
                            padding: 5px 24px;
                            width: 144px;
                        }
                    }
                }
            }
        }
    }

    .blog_page_tabs {
        .tabs_wrapper {
            border-bottom: 1px solid rgba(59, 61, 65, 0.3);
            .tabs {
                margin-top: 25px;

                .MuiTabs-indicator.css-1aquho2-MuiTabs-indicator {
                    background-color: #434447!important;
                }
                .MuiTabs-flexContainer{
                    justify-content: start!important;
                    height: 100%;
                    .Mui-selected{
                        color: #434447!important;
                    }

                    button{
                        padding: 13px 0!important;
                        min-height: auto!important;
                        margin-bottom: 0;
                        color: #86888C!important;
                    
                        &:first-child{
                            margin-left: 0!important;
                        }
                    }
                }
            }
        }
    }

    .single_blog_page {
        .blog_details {

            .wrapper{
                margin-top: 30px!important;
            }
            .head {
                margin-bottom: 25px;
                .title{
                    color: #4C4E52;
                }
                .sub_details {
                    font-size: 13px;
                    margin-bottom: 20px;
                    margin-top: 10px;
                    color: #4C4E52;
                    span{
                        padding: 0 10px;
                    }
                }
            }

            .text_section {
                .section{
                    font-size: 16px;
                    margin-bottom: 25px;
                    &.title{
                        font-size: 24px;
                    }
                }
            }
        }
    }

    

    .blog_items{
        margin-top: 25px;
        .wrapper {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            .blog_item{
                border-radius: 8px 8px 0 0;
                overflow: hidden;
                margin-bottom: 45px;

                &:hover{
                    .title{
                        color: #287BDD!important;
                    }
                }

                .sub_title{
                    font-size: 13px;
                    margin: 10px 0;
                    color: #86888C;
                }
                .title{
                    font-size: 24px;
                    color: #434447;
                    margin-bottom: 15px;
                }
                .bottom{
                    font-size: 13px;
                    display: flex;
                    justify-content: space-between;
                    color: #434447;

                    .date{
                        span{
                            padding: 0 8px;
                            font-weight: bold;
                        }
                    }
                }
            }
        }
    }

    .related_news {
        background: rgba(196, 196, 196, 0.4);
        padding: 50px 0;
        padding-bottom: 80px;
        .title{
            color: #4C4E52;
            margin-bottom: 25px;
        }
    }
}





//contact page

.form_container {
    padding: 20px;
    width: 683px;
    margin: 50px auto;
    font-family: "Lato";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    @media (max-width: 768px) {
      max-width: 683px;
      width: 90vw;
    }
    .inputarea,
    .textarea {
      display: flex;
      flex-direction: column;
      justify-content: left;
      width: 100%;
      margin: 20px 0px;
      label {
        font-family: "Lato";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 25px;
      }
      .inputfield_full,
      .textarea_full {
        width: 100%;
        max-width: 680px;
        height: 45px;
        background: rgba(75, 97, 117, 0.2);
        border: 1px solid rgba(255, 255, 255, 0.24);
        box-sizing: border-box;
        backdrop-filter: blur(128px);
        border-radius: 4px;
        padding: 10px 5px;
        color: #ffffff;
      }
      .textarea_full {
        height: 115px;
        text-align: start;
        font-family: inherit;
      }
    }
    .inline_flex_1,
    .inline_flex_2 {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      .inputarea_left {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 210px;
        height: 100%;
        margin-right: 20px;
        .field_1 {
          width: 100%;
          max-width: 1/3;
          height: 45px;
          background: rgba(75, 97, 117, 0.2);
          border: 1px solid rgba(255, 255, 255, 0.24);
          backdrop-filter: blur(128px);
          border-radius: 4px;
          padding: 10px 5px;
          color: #ffffff;
        }
      }
      .inputarea_right {
        display: flex;
        flex-direction: column;
        justify-content: left;
        margin: 0px 20px;
        width: 432px;
        height: 100%;
        margin: 0px;
        .field_2 {
          border: #1baafa 1px solid;
          width: 100%;
          max-width: 422px;
          height: 45px;
          background: rgba(75, 97, 117, 0.2);
          border: 1px solid rgba(255, 255, 255, 0.24);
          backdrop-filter: blur(128px);
          border-radius: 4px;
          padding: 10px 5px;
          color: #ffffff;
        }
      }
    }
    .inline_flex_2 {
      flex-direction: row-reverse;
      .inputarea_left {
        margin: 0px;
      }
      .inputarea_right {
        margin-right: 20px;
      }
    }
    .btn {
      width: 100%;
      margin: 30px 0px;
      button {
        padding: 12px 56px;
        width: 100%;
        max-width: 683px;
        height: 48px;
        background: #287bdd;
        border: none;
        border-radius: 6px;
        color: #ffffff;
        font-family: inherit;
      }
    }
  }
   




// career page 
// #########################
// ##################################
.career_page {
    .career_main_wrapper {
        margin: auto;
        margin-top: 50px;

        .title{
            margin-bottom: 40px;
        }
        .items {
            .item{
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 30px;
                border-bottom: 1px solid #737373;
                padding-bottom: 20px;            

                .title_sub{
                    font-weight: 500;
                    font-size: 24px;
                }
                .des{
                    font-weight: 400;
                    font-size: 16px;
                    padding: 6px 22px;
                    background: transparent;
                    border: 1px solid #737373;
                    border-radius: 32px;

                    &:hover{
                        background: #287BDD;
                    }
                }
            }
        }
    }

    .description_page {
        .back_btn {
            button{
                color: #fff;
                margin-bottom: 20px;
            }
        }
        .text_section{
            margin-bottom: 25px;
            line-height: 1.4;
            font-weight: 300;
            font-size: 16px;
        }
        .title_sub{
            margin-bottom: 25px;                    
            font-weight: 500;
            font-size: 24px;
        }

        .apply_form_wrapper{
            width: 100%;
            height: auto;
            padding: 10px 20px;
            background: rgba(75, 97, 117, 0.17);
            border: 1px solid rgba(255, 255, 255, 0.222);
            backdrop-filter: blur(128px);
            border-radius: 8px;
            margin-top: 50px;
            
            .head {
                display: flex;
                align-items: center;
                justify-content: space-between;
                cursor: pointer;
                .text{
                    font-size: 24px;
                }
                .icon.show{
                    svg{
                        transform: rotate(180deg);
                    }
                }
                .icon svg{
                    width: 35px;
                    height: 35px;
                    transition: all .4s;
                }
            }

            .form_wrapper{
                width: 100%;
                margin-top: 20px;
                padding-top: 10px;
                border-top: 1px solid rgba(255, 255, 255, 0.32);
                .form_group{
                    margin: 15px 0;

                    button{
                        width: 100%;
                        margin-top: 15px;
                        margin-bottom: 20px;
                        background: #287BDD;
                        border-radius: 6px;
                        color: #fff;
                        text-transform: none;
                        font-size: 16px;
                    }
                }
                label{
                    font-size: 13px;
                }
                input{
                    width: 100%;
                    background: rgba(75, 97, 117, 0.2);
                    border: 1px solid rgba(255, 255, 255, 0.24);
                    border-radius: 4px;
                    padding: 10px;
                    margin-top: 6px;
                    outline: none;
                    color: #fff;
                }
            }
        }
    }
}