*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    transition: all .001s;
}

body{
    background: #020305;
    color: #ffffff;
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 16px;
    position: absolute;
    width: 100%;
    height: 100%;
}

body > .body_white {
    background: #fff;
    color: #000;
}


a{
    color: inherit;
    text-decoration: none;
}
img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    user-select: none;
}

.center{
    text-align: center;
}
.title{
    font-size: 42px;
    font-weight: 700;
}

// display
.flex{
    display: flex;
    &.jc_center{
        justify-content: center;
    }
    &.ai_center{
        align-items: center;
    }
    &.jc_sb{
        justify-content: space-between;
    }
}
.relative{
    position: relative;
}


.main_content{
    padding: 0 3.1746031746%;
    padding-top: 120px;
    max-width: 1500px;
    margin: auto;
}
.page_content {
    max-width: 1500px;
    margin: auto;
    width: 100%;
    padding: 0 3.1746031746%;
}

.mb_150{
    margin-bottom: 150px;
}


.grid_wrapper{
    position: absolute;
    width: 100%;
    height: 1000%;
    z-index: -1;
    .grid{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        padding: 0 3.1746031746%;
        max-width: 1500px;
        margin: auto;
        div{
            width: 6.77966101695%;
            background: #89848430;
        }
    }
}




// page
.page_wrapper {
    width: 100%;
    padding-top: 100px;
    position: relative;

    .page_title {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40vh;
        min-height: 140px;
        max-height: 200px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        h1{
            font-size: 62px;
            font-weight: 700;
            text-align: center;
        }
    }
}

// tabs 
.tabs {
    .MuiTabs-flexContainer{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 2px solid rgba(255, 255, 255, 0.19);;

        button{
            width: auto;
            min-width: auto!important;
            padding: 0!important;
            color: $gray_text!important;
            margin: 0 20px;
            &.Mui-selected {
                color: #fff!important;
            }
        }
    }
    .MuiTabs-indicator.css-1aquho2-MuiTabs-indicator {
        background-color: #fff!important;
    }
}





// animations 
@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translate3d(0, 100%, 0)
    }
    to {
        opacity: 1;
        transform: none
    }
}
.fadeInUp {
    animation-name: fadeInUp;
    animation-duration: 1s;
}
.square {
    width: 100%;
    height: 200px;
    background-color: blue;
}

// particles 
.page_title_wrapper_with_particles {
    position: relative;
    .heroParticlesWrapper{
        position: absolute;
        width: 100%;
        top: 0;
        bottom: 0;
        overflow: hidden;
        #tsparticles {
            width: 100%;
            height: 100%;
        }
        canvas{
            position: relative!important;
        }
    }
}


