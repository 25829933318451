.Header{
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    height: 100px;
    // border-bottom: 1px solid rgba(179, 188, 190, 0.24);
    background: #020305db;
    z-index: 9999;

    .header_wrapper{
        width: 100%;
        padding: 10px 3.1746031746%;
        max-width: 1500px;
        margin: auto;
        height: 100%;

        .mobile_menu {
            width: 100%;
            justify-content: space-between;

            .menu_bar button{
                border-radius: 100%;
                width: 50px;
                height: 60px;
                svg{
                    font-size: 30px;
                    color: #fff;
                }
            }
        }

        .link_items{
            .item{
                margin: 0 25px;
                &:first-child{
                    margin-left: 35px;
                }
                &:last-child{
                    margin-right: 0;
                }
                a{
                    color: #B2B7BB;
                    &.active{
                        color: #fff;
                    }
                    &:hover{
                        color: #fff;
                    }
                }
            }
        }

        .right{
            .item{
                color: #B2B7BB;
                &:first-child{
                    margin-right: 20px;
                }
                &.active{
                    color: #fff;
                }
                &:hover{
                    color: #fff;
                }
            }
        }
    }
}

body.bg_white{
    background-color: #fff!important;
    color: #000!important;

    .Header {
        background: #020305db;
        color: #fff;
        border-bottom: none;
    }
    .Header .header_wrapper .link_items .item a {
        color: #fff;
    }
    .Header .header_wrapper .link_items .item a.active {
        color: #287BDD;
    }
}



.mobile_menus_w{
    width: 100%;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
    .overlay{
        width: 100%;
        height: 100%;
        z-index: -1;
        background: #00000057;
        position: absolute;
    }
    .animation_wrapper{
        width: 100%!important;
        max-width: 250px;
        height: 100%!important;
        background: #001337bd;
        z-index: 99999999;
        .wrapper{
            padding: 25px;
            padding-top: 120px;

            .flex{
                flex-wrap: wrap;
                .item{        
                    width: 100%;
                    margin-bottom: 10px;
                    :hover{
                        color: #0c86ff;
                    }
                }
            }
        }
    }
}


.footer{
    border-top: 1px solid rgba(179, 188, 190, 0.24);
    padding-top: 40px;
    background: #020305;    
    padding-bottom: 10px;

    .footer_wraper{
        display: flex;
        justify-content: space-between;

        .left{
            .logo{
                width: 85px;
                height: 85px;
                margin: auto;
            }
            .title{
                font-size: 30px;
                margin-top: 12px;
                font-weight: bold;
                letter-spacing: 2px;
            }
        }

        .right {
            width: 50%;
            display: flex;
            .items {
                width: 33.3%;

                .item {
                    color: $gray_text;
                    font-size: 14px;
                    margin-bottom: 15px;
                    &.title{
                        font-weight: bold;
                        color: #fff;
                        margin-bottom: 20px;
                    }
                    a:hover{
                        color: #fff;
                    }
                }
            }
        }
    }

    .social_icons {
        width: 100%;
        padding: 20px 0;
        border-bottom: 1px solid rgba(179, 188, 190, 0.2);
        margin-bottom: 20px;
        .items{
            display: flex;
            justify-content: space-around;
            max-width: 200px;
            width: 100%;
            svg{
                width: 20px;
                height: 20px;
                color: $gray_text;
                &:hover{
                    color: #fff;
                }
            }
        }
    }
    .copyright{
        margin: 10px 0 20px;
        font-size: 13px;
    }

    @media (max-width: 991px) {
        .copyright {
            text-align: center;
        }
    }
}